@import "_variables";
@import "_include";
@import "./fontawesome/fontawesome.scss";
@import "./fontawesome/solid.scss";
@import "prices";

ul.list-icon {
  color: #777777;
}

.h-s1 {
  height: $spacer;
}
.highlight-text {
  background: rgba($color: #fff, $alpha: 0.5);
  padding-left: 1em;
  padding-right: 1em;
}
.highlight-light-text {
  background: rgba($color: #000, $alpha: 0.2);
  padding-left: 1em;
  padding-right: 1em;
}
.day-time-placeholder {
  background-color: $color-primary;
  opacity: 0.6;
}
.sanskrit {
  font-family: "Libre Baskerville", serif;
  font-size: 1.5rem;
  color: $color-primary;
}
.text-logo {
  font-family: "Lexend Deca", sans-serif !important;
  display: inline !important;
  // color: $color-primary;
}
.avatar-big {
  width: $spacer * 8;
  height: $spacer * 8;
}

.yoga-timetable {
  color: #fff;
  h4 {
    font-size: 1.25em;
  }
  h5,
  h4 {
    color: #fff;
    margin-bottom: 0px;
  }
  .heading-text {
    padding: 10px;
    margin-bottom: 8px;
    background-color: $color-primary;
  }

  .day-plan {
    color: #fff;
    p {
      color: #fff;
    }
    .heading-text {
      margin-bottom: 4px;
    }

    .beginners-level,
    .beginner-level {
      background-color: $color-secondary;
    }
    .beginner-level-low {
      background-color: #1e98c8;
    }
    .kk-beginner-level {
      background-color: #02b5fc;
    }
    .aks-beginner-level {
      background-color: #C3CD85;
    }
    .beginner-medium-level {
      background-color: #58c065;
    }
    .medium-level {
      background-color: #107440;
    }
    .business-level {
      background-color: orange;
    }
    .intensive-level,
    .intensiv-level {
      background-color: #f585d5;
    }
    .super-beginner {
      background-color: $color-info;
    }
    .schwanger {
      background-color: #8624ad;
    }
    .schwanger {
      background-color: #8624ad;
    }
    .cathy-yoga {
      background-color: #FD9366;
    }

    .break {
      background-color: #002f49;
    }

    .day-time {
      padding: 10px;
      margin-bottom: 4px;

      li {
        text-decoration: none;
        list-style: none;
        padding-left: 10px;
      }
    }
  }
  .announcement {
    // height: 100%;
    background-color: #bc175c;
    .heading-text {
      // height: 100%;
      background-color: #bc175c;
    }
  }
  div[class*="col-"] {
    padding-right: 2px;
    padding-left: 2px;
  }
}

.testimonial-item.testimonial-item-dark {
  p,
  span {
    color: #fff;
  }
}

@include breakpoint-lg(min) {
  .avatar {
    &.avatar-big {
      width: $spacer * 9;
      height: $spacer * 9;
    }
  }
}
.portfolio-headline-yoga {
  left: 0;
  margin: 0 20;
  padding: 20px;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 48%;
  transform: translate3d(0px, -38%, 0px);
  transition: all 250ms ease-in-out 0s;
  visibility: hidden;
  width: auto;
  z-index: 3;
}
.portfolio-item-yoga {
  /*hover state*/
  &:not(.no-overlay) {
    .portfolio-description {
      margin: 0;
      width: auto;
    }
    .portfolio-headline-yoga {
      margin: 0 auto;
      color: $dark;
      transform: translate3d(0, -50%, 0);
      opacity: 1;
      visibility: visible;
      h3 {
        transform: translateY(0px);
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        letter-spacing: 1px;
        line-height: 18px;
        text-transform: uppercase;
        margin-bottom: 4px;
      }
    }
  }
  &:not(.no-overlay):hover {
    .portfolio-headline-yoga {
      visibility: hidden;
      opacity: 0;
    }
  }
}
.testimonials-bg-image {
  background-image: url("/assets/images/yogamitlia/testimonials_background.jpg");
  ol.flickity-page-dots {
    bottom: 30px;
  }
}
.inspiro-slider {
  .slide {
    .slide-captions {
      h1{
        font-size: 70px !important;
      }

      h2 {
        .long-slider-text {
          font-size: 60px;
        }
      }
    }
  }
}
@include breakpoint-lg(max) {
  .inspiro-slider {
    .slide {
      .slide-captions {
        h1{
          font-size: 40px !important;
        }
        
        h2 {
          .long-slider-text {
            font-size: 30px;
          }
        }
      }
    }
  }
}
