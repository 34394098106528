/* @include breakpoint-lg(max) {

    a>span.logo-default::after,
    a>span.logo-dark::after {
        position: absolute;
        content: "";
        background: #2250fc;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        right: 0px;
        top: 20px;
    }

    a>span.logo-default::before,
    a>span.logo-dark::before {
        position: absolute;
        content: "5";
        font-weight: 600;
        font-size: 10px;
        right: 2px;
        top: -14px;
        z-index: 1;
        color: #ffffff;
    }

    #header.header-mini {

        a>span.logo-default::after,
        a>span.logo-dark::after {
            width: 12px;
            height: 12px;
            right: 1px;
            top: 11px;
        }
    }
} */