/***********************************************************************
**														              **
**         POLO Html5 Template		   								  **
**         Author: INSPIRO MEDIA								      **
**		   URL: //www.inspiro-media.com						  **
**	       													              **
************************************************************************/
/*  Content & Layout */
@import "layout";
@import "page-title";
@import "content";
@import "grid";
/*  Header */
@import "topbar";
@import "header";
@import "menu";
@import "header-extras";
@import "search";
@import "side-panel";
@import "page-menu";
@import "sidebar";
/*  Typography */
@import "typography";
/*  Sliders */
@import "sliders";
/*  Portfolio */
@import "portfolio";
/*  Blog & comments */
@import "blog";
@import "comments";
/*  Shop */
@import "shop";
/*  Forum */
@import "forum";
/*  Footer */
@import "footer";
/*  Widgets */
@import "widgets";
/*  Extras */
@import "extras";
/*  Helpers */
@import "helpers";