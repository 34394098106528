//Grid Layout
.grid-layout {
    overflow: hidden;
    margin-bottom: -2px !important;

    >* {
        opacity: 0;
        transition: opacity .5s ease;
    }

    .grid-loader {
        transition: opacity .1s ease;
        opacity: 1;
        z-index: -1;
    }

    &.grid-loaded {
        >* {
            opacity: 1;
        }

        .grid-loader {
            opacity: 0;
        }
    }
}

.grid-layout,
.portfolioo,
.posts {
    position: relative;
    display: block;
    clear: both;
    padding: 0;
}

.grid-mix-size {
    .portfolio-item:nth-child(2n) {
        .new-badge {
            right: 70px !important;
        }

        >.portfolio-item-wrap {
            -webkit-transform: scale(.75);
            transform: scale(.8);
        }
    }
}

/*Grid columns: 5*/
.grid-6-columns .grid-item,
.post-6-columns .post-item,
.portfolio-6-columns .portfolio-item {
    width: 16.6666666666%;

    &.large-width {
        width: 33.333333333%;
    }
}

/*Grid columns: 5*/
.grid-5-columns .grid-item,
.post-5-columns .post-item,
.portfolio-5-columns .portfolio-item {
    width: 20%;

    &.large-width {
        width: 40%;
    }
}

/*Grid columns: 4*/
.grid-4-columns .grid-item,
.post-4-columns .post-item,
.portfolio-4-columns .portfolio-item {
    width: 25%;

    &.large-width {
        width: 50%;
    }
}

/*Grid columns: 3*/
.grid-3-columns .grid-item,
.post-3-columns .post-item,
.portfolio-3-columns .portfolio-item {
    width: 33.333333333%;

    &.large-width {
        width: 66.6666666666%;
    }
}

/*Grid columns: 2*/
.grid-2-columns .grid-item,
.post-2-columns .post-item,
.portfolio-2-columns .portfolio-item {
    width: 50.00%;

    &.large-width {
        width: 100%;
    }
}

/*Grid columns: 1*/
.grid-1-columns .grid-item,
.post-1-columns .post-item,
.portfolio-1-columns .portfolio-item {
    width: 100%;

    &.large-width {
        width: 100%;
    }
}

@media (max-width: 1380px) {

    .grid-5-columns .grid-item,
    .post-5-columns .post-item,
    .portfolio-5-columns .portfolio-item {
        width: 25%;

        &.large-width {
            width: 50%;
        }
    }
}

/*Tablet Landscape*/
@include breakpoint-lg(max) {

    /*Grid columns: 6,5,4*/
    .grid-6-columns .grid-item,
    .post-6-columns .post-item,
    .portfolio-6-columns .portfolio-item,
    .grid-5-columns .grid-item,
    .post-5-columns .post-item,
    .portfolio-5-columns .portfolio-item,
    .grid-4-columns .grid-item,
    .post-4-columns .post-item,
    .portfolio-4-columns .portfolio-item {
        width: 33.3333333333%;

        &.large-width {
            width: 66.6666666666%;
        }
    }

    /*Grid columns: 3,2*/
    .grid-3-columns .grid-item,
    .post-3-columns .post-item,
    .portfolio-3-columns .portfolio-item,
    .grid-2-columns .grid-item,
    .post-2-columns .post-item,
    .portfolio-2-columns .portfolio-item {
        width: 50%;

        &.large-width {
            width: 100%;
        }
    }
}

/*Tablet Portrait*/
@media (max-width: 767px) {

    /*Grid columns: 6,5,4*/
    .grid-6-columns .grid-item,
    .post-6-columns .post-item,
    .portfolio-6-columns .portfolio-item,
    .grid-5-columns .grid-item,
    .post-5-columns .post-item,
    .portfolio-5-columns .portfolio-item,
    .grid-4-columns .grid-item,
    .post-4-columns .post-item,
    .portfolio-4-columns .portfolio-item {
        width: 50%;

        &.large-width {
            width: 100%;
        }
    }

    /*Grid columns: 3,2*/
    .grid-3-columns .grid-item,
    .post-3-columns .post-item,
    .portfolio-3-columns .portfolio-item,
    .grid-2-columns .grid-item,
    .post-2-columns .post-item,
    .portfolio-2-columns .portfolio-item {
        width: 50%;

        &.large-width {
            width: 100%;
        }
    }
}

/*Tablet Portrait*/
@media (max-width: 480px) {

    /*Grid columns: 6,5,4,3,2*/
    .grid-6-columns .grid-item,
    .post-6-columns .post-item,
    .portfolio-6-columns .portfolio-item,
    .grid-5-columns .grid-item,
    .post-5-columns .post-item,
    .portfolio-5-columns .portfolio-item,
    .grid-4-columns .grid-item,
    .post-4-columns .post-item,
    .portfolio-4-columns .portfolio-item,
    .grid-3-columns .grid-item,
    .post-3-columns .post-item,
    .portfolio-3-columns .portfolio-item,
    .grid-2-columns .grid-item,
    .post-2-columns .post-item,
    .portfolio-2-columns .portfolio-item {
        width: 100%;

        &.large-width {
            width: 100%;
        }
    }

    .grid-xs-2-columns .grid-item,
    .post-xs-2-columns .post-item,
    .portfolio-xs-2-columns .portfolio-item {
        width: 50%;

        &.large-width {
            width: 100%;
        }
    }

    .grid-xs-3-columns .grid-item,
    .post-xs-3-columns .post-item,
    .portfolio-xs-3-columns .portfolio-item {
        width: 33.3333333333%;

        &.large-width {
            width: 66.6666666666%;
        }
    }

    .grid-xs-4-columns .grid-item,
    .post-xs-4-columns .post-item,
    .portfolio-xs-4-columns .portfolio-item {
        width: 25%;

        &.large-width {
            width: 25%;
        }
    }
}

/*Grid item */
.grid-item .post-item {
    width: 100%;
    padding: 0;
}

.grid-item>img,
.grid-item>a>img {
    width: 100%;
    height: auto;
}

.grid-item>.widget {
    margin: 0;
}

.grid-item {
    height: auto !important;
}

/* ----------------------------------------------------------------
	Grid Filter
-----------------------------------------------------------------*/
.grid-filter {
    clear: both;
    display: block;
    //height: 34px;
    width: 100%;
    margin-bottom: 26px;
    position: relative;

    ul {
        list-style: none;
        padding: 0;
        position: relative;
        height: auto;
    }

    li {
        position: relative;
        margin-right: 6px;
        border: 0;
        margin-bottom: -1px;
        display: inline-block;

        a {
            font-weight: 600;
            display: block;
            position: relative;
            padding: 8px 10px;
            font-size: 13px;
            line-height: 15px;
            color: $color-body;
            border-radius: 50px;
            transition: all .3s ease;
            border-width: 2px;
            border-style: solid;
            border-color: transparent;
        }

        &:hover,
        &.active {

            a,
            a:hover,
            a:active,
            a:focus {
                background-color: $color-theme;
                color: $color-white;
            }
        }

        &.active {
            cursor: pointer;
            pointer-events: none;

            a {
                color: $color-white;
                pointer-events: none;
            }
        }
    }

    &.gf-outline {
        li {

            &:hover,
            &.active {
                a {
                    border-color: $color-theme;
                    color: $color-theme;
                    background-color: transparent;
                }
            }
        }
    }

    &.gf-lines {
        li {
            a {
                border-radius: 0;
                border: none;
                background-color: transparent !important;

                &:after {
                    content: '';
                    position: absolute;
                    top: auto;
                    bottom: -2px;
                    left: 50%;
                    width: 0;
                    height: 2px;
                    transition: all 0.3s ease;
                }
            }

            &:hover,
            &.active {
                a {
                    color: $color-theme;

                    &:after {
                        width: 100%;
                        left: 0%;
                        background-color: $color-theme;
                    }
                }
            }
        }
    }

    &.gf-line-bottom {
        border-bottom: 2px solid #ebebeb;
    }

    &.gf-classic {
        li {
            a {
                color: $color-body;
                border-radius: 4px;
            }

            &:hover,
            &.active {
                a {
                    background-color: $color-theme;
                    color: $color-white;
                }
            }
        }
    }

    &.gf-light {
        li {
            a {
                color: $color-white;
                background-color: transparent;
            }

            &:hover,
            &.active {
                a {
                    color: $color-white;
                    border-color: $color-white;
                    background-color: transparent;
                }
            }
        }
    }

    &.gf-dark {
        li {
            a {
                color: $dark;
                background-color: transparent;
            }

            &:hover,
            &.active {
                background-color: transparent;

                a {
                    background-color: transparent;
                    color: $dark;
                    border-color: $dark;
                }
            }
        }
    }

    &.gf-default {
        li {
            a {
                color: none;
                border-radius: 0;
                background-color: transparent;
            }

            &:hover,
            &.active {
                a {
                    background-color: transparent;
                    color: $color-theme;
                }
            }
        }
    }

    &.gf-creative {
        li {
            a {
                border-radius: 0;
                border: none;

                &:after {
                    background: $dark;
                    content: "";
                    height: 2px;
                    left: 0;
                    opacity: 0;
                    position: absolute;
                    top: 100%;
                    transform: translateY(10px);
                    transition: opacity 0.3s ease 0s, transform 0.3s ease 0s;
                    width: 100%;
                }
            }

            &:hover,
            &.active {
                background-color: transparent;

                a {
                    color: $color-body;
                    background-color: transparent;

                    &:after {
                        opacity: 1;
                        transform: translateY(0px);
                    }
                }
            }
        }
    }

    &.gf-list {
        li {
            clear: both;
            margin-bottom: 3px;
        }
    }

    &.center {
        li {
            text-align: center;
            float: none;
        }
    }
}

.grid-active-title {
    font-size: 3rem;
    font-weight: 100;
    opacity: 0.1;
    position: absolute;
    right: 0;
    top: -6px;
}

.content .grid-active-title {
    font-size: 42px;
}

.sidebar-both .grid-active-title {
    display: none;
}

@include breakpoint-lg(max) {
    .grid-active-title {
        display: none;
    }

    .grid-filter {
        li {
            margin-right: 0;
            margin-bottom: 4px;

            a {
                text-align: center;
                transition: none;
            }
        }
    }
}

@media (max-width: 479px) {
    .grid-filter { 
        li {
            margin-right: 0;
        }
    }
}

/* ----------------------------------------------------------------
	Extras
-----------------------------------------------------------------*/
.infinite-scroll-message,
#showMore {
    height: 80px;
    padding-top: 36px;
    text-align: center;

    p {
        margin: 0;
    }
}

//Grid Loader Animation
.grid-loader {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: load7 1.8s infinite ease-in-out;
    animation: load7 1.8s infinite ease-in-out;
    position: absolute;
    top: 60px;
    left: 48%;
    color: #dadada;
    font-size: 6px;
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;

    &:before,
    &:after {
        border-radius: 50%;
        width: 2.5em;
        height: 2.5em;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation: load7 1.8s infinite ease-in-out;
        animation: load7 1.8s infinite ease-in-out;
    }

    &:before,
    &:after {
        content: '';
        position: absolute;
        top: 0;
    }

    &:before {
        left: -3.5em;
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
    }

    &:after {
        left: 3.5em;
    }
}

@-webkit-keyframes load7 {

    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }

    40% {
        box-shadow: 0 2.5em 0 0;
    }
}

@keyframes load7 {

    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }

    40% {
        box-shadow: 0 2.5em 0 0;
    }
}