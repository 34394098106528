// Countdown timer
.countdown-container {
	display: table;
	margin: 0 auto;
}

.countdown {
	color: $color-body;
	overflow: hidden;

	.countdown-box {
		display: block;
		float: left;
		margin: 24px;
		overflow: hidden;
		position: relative;
		text-align: center;
		width: auto;
	}

	.number {
		font-size: 72px;
		font-weight: 600;
		line-height: 1;
		margin-bottom: 10px;
		text-align: center;
	}

	span {
		display: block;
		font-size: 18px;
		font-weight: 400;
		overflow: hidden;
		padding-top: 4px;
		position: relative;
		text-align: center;
		color: $color-dark;

		&:before {
			background: none repeat scroll 0 0 $color-dark;
			content: "";
			display: block;
			height: 1px;
			left: 0;
			margin-left: auto;
			margin-right: auto;
			position: absolute;
			right: 0;
			top: 0;
			width: 30px;
			opacity: .3;
		}
	}

	// Circle Style
	&.circle {
		.countdown-box {
			border: 3px solid $color-dark;
			border-radius: 50%;
			color: $dark;
			height: 160px;
			padding: 34px 5px 0 0;
			width: 160px;
			margin: 20px 15px;

			.number {
				font-size: 52px;
				font-weight: 800px;
			}

			span {
				font-size: 18px;
			}
		}
	}

	// Rectangle style
	&.rectangle {
		.countdown-box {
			border: 4px solid;
			padding: 24px;
			width: 170px;
			margin-left: 15px;
			margin-right: 15px;

			.number {
				font-size: 52px;
				font-weight: 600;
			}

			span {
				font-size: 18px;
			}
		}
	}

	// Medium size
	&.medium,
	&.circle.medium,
	&.rectangle.medium {
		.countdown-box {
			height: 90px;
			margin: 6px;
			padding: 20px 4px 0 0;
			width: 90px;
		}

		.number {
			font-size: 30px;
			margin-bottom: 4px;
		}

		span {
			font-size: 12px;
		}
	}

	&.circle.medium,
	&.rectangle.medium {
		.countdown-box {
			border: solid 2px;
		}
	}

	// Small size
	&.small,
	&.circle.small,
	&.rectangle.small {
		.countdown-box {
			margin: 10px;
			height: 60px;
			padding: 10px 0px 0 0;
			width: 60px;
			margin: 5px 2px;
		}

		.number {
			font-size: 14px;
			margin-bottom: 6px;
		}

		span {
			font-size: 10px;
			padding-top: 0px;
		}
	}

	&.circle.small,
	&.rectangle.small {
		.countdown-box {
			border: solid 2px;
		}
	}

	// Light & Dark
	&.light .countdown-box {
		border-color: $color-white;
		color: $color-white;
		text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3);
	}

	&.countdown-light {
		span:before {
			background: $color-white;
		}

		span,
		.number {
			color: $color-white !important;
		}

		&.circle .countdown-box {
			border-color: $color-white;
		}

		&.rectangle .countdown-box {
			border-color: $color-white;
		}
	}

	&.countdown-dark {

		span,
		.number {
			color: $color-dark !important;
		}
	}
}

@include breakpoint-lg(max) {
	.countdown .countdown-box {
		height: 60px !important;
		padding: 2px 0px 0 0 !important;
		width: 60px !important;
		margin: 0px !important;
		border: solid 0px !important;
	}

	.countdown .countdown-box .number {
		font-size: 20px !important;
		margin-bottom: 0px;
	}

	.countdown .countdown-container .countdown-box span {
		font-size: 10px !important;
		padding-top: 0px;
	}
}