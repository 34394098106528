// Large Boxes
.box-fancy {
	>.container {
		padding: 0 30px;
	}

	[class*="col-"] {
		padding: 5% !important;
	}

	&.fancy-box-over {
		top: -100px;
	}
}

body.breakpoint-xs .box-fancy [class*="col-"] {
	padding: 10% !important;
}

.over-slider-top {
	top: -100px;
}

// Site Map
.site-map {
	i {
		margin-right: 8px;
	}

	ul li {
		padding-bottom: 2px;
	}
}

// Animations
.animated {
	&.rounded {
		animation-fill-mode:none;
	}
	&.infinite {
		animation-duration: 3s;
		animation-fill-mode: both;
	}
}

[data-animate] {
	opacity: 0;
}

[data-animate].visible {
	opacity: 1;
}

.icon[data-animate] {
	opacity: 1;
}

//Rating
.rateit,
.rateit-font .rateit-range {
	overflow: hidden !important;
}

.rateit,
.rateit-font .rateit-range>div,
.rateit>div {
	cursor: pointer !important;
}

.rateit-selected {
	color: #FFC400 !important;
}

.rateit-reset {
	display: none !important;
}